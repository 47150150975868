import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, IconButton, InputAdornment } from "@mui/material";
import * as React from "react";
import DialogCustom from "../../common/components/DialogCustom";
import { some } from "../../common/constants";
import SchemaForm from "../../common/SchemaForm";
import { EMAIL_REGEX } from "../../config/regex";

export const ROLE_OPTIONS = [
  {
    id: "MEDRING_ADMIN",
    name: "Admin",
  },
  {
    id: "MEDRING_USER",
    name: "User",
  },
];

interface Props {
  onSubmit: (formVal: some, setLoading: (loading: boolean) => void) => void;
  open: boolean;
  onClose: () => void;
  formData?: some;
  roles?: some;
}

const SchemaFormContent: React.FunctionComponent<Props> = (props) => {
  const { onSubmit, formData, onClose, roles } = props;
  return (
    <>
      <SchemaForm
        onSubmit={onSubmit}
        initialData={formData}
        onCancel={onClose}
        schema={{
          fields: ({ formProps: { intl, state, setState }, valuesField }) => {
            return {
              username: {
                type: "text-field",
                label: intl.formatMessage({ id: "user.name" }),
                placeholder: intl.formatMessage({ id: "enter" }),
                register: { required: true },
                propsWrapper: { xs: true },
                readOnly: !!valuesField?.id,
              },
              roleId: {
                type: "select",
                label: intl.formatMessage({ id: "user.role" }),
                placeholder: intl.formatMessage({ id: "select" }),
                register: { required: true },
                propsWrapper: { xs: true },
                options: roles,
                readOnly: !!valuesField?.id,
              },
              email: {
                type: "text-field",
                label: intl.formatMessage({ id: "email" }),
                placeholder: intl.formatMessage({ id: "enter" }),
                register: {
                  required: true,
                  pattern: {
                    value: EMAIL_REGEX,
                    message: intl.formatMessage({ id: "enter" }),
                  },
                },
                propsWrapper: { xs: true },
              },
              password: {
                type: "text-field",
                hidden: !!formData?.id,
                inputType: state?.showPassword ? "text" : "password",
                label: intl.formatMessage({ id: "password" }),
                placeholder: intl.formatMessage({ id: "enter" }),
                propsWrapper: { xs: true },
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        tabIndex={-1}
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setState({ showPassword: !state.showPassword })
                        }
                      >
                        {!state.showPassword ? (
                          <VisibilityOff fontSize="small" />
                        ) : (
                          <Visibility fontSize="small" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                  style: { borderRadius: 4, overflow: "hidden" },
                },
                register: {
                  required: true,
                },
              },
            };
          },
        }}
        formProps={{
          autoComplete: "nope",
          autoCorrect: "off",
        }}
      />
    </>
  );
};

const UserDialog: React.FunctionComponent<Props> = (props) => {
  const { open, onClose, formData } = props;

  return (
    <DialogCustom
      open={!!open}
      keepMounted={false}
      onClose={onClose}
      PaperProps={{
        style: { minWidth: 780 },
      }}
      maxWidth="xl"
      title={formData?.id ? "user.update" : "user.add"}
    >
      <Box padding={2}>
        <SchemaFormContent {...props} />
      </Box>
    </DialogCustom>
  );
};
export default UserDialog;
