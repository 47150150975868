import moment from "moment";
import { BE_DATE_FORMAT, DURATION_TYPE } from "../../common/constants";

export const caculateEndDateOfTrial = (
  startDate: string,
  durationNumber: number,
  durationType: keyof typeof DURATION_TYPE
) => {
  return moment(startDate, BE_DATE_FORMAT)
    .add(
      durationNumber,
      durationType === DURATION_TYPE.DAY.id ? "day" : "month"
    )
    .format(BE_DATE_FORMAT);
};
