import { Box } from "@mui/material";
import * as React from "react";
import { TOKEN } from "../../common/constants";
import useGeneralHook from "../../common/hook/useGeneralHook";
import { background_png } from "../../svg";
import LoginBox, { ILogin } from "../components/LoginBox";
import jwt_decode from "jwt-decode";
import {
  authenticationIn,
  saveUserInfo,
} from "../reducer/authenticationReducer";
import { getMachineId } from "../../config/utils";

interface Props {}

interface JwtDecoded {
  token_id: string;
  token_type: string;
  organization_id: number;
  organization_code: string;
  organization_name: string;
  group_id: number;
  group_name: string;
  group_type: string;
  user_id: number;
  user_name: string;
  role: string[];
  partition_id: number;
  location_id: number;
  resource_id: number;
  sub: string;
  iat: string;
  exp: string;
}

const LoginPage = (props: Props) => {
  const { dispatch, API_PATHS, fetchThunk, enqueueSnackbar } = useGeneralHook();

  const onSubmit = React.useCallback(
    async (values: ILogin, setLoading: (loading: boolean) => void) => {
      setLoading(true);
      try {
        const json = await dispatch(
          fetchThunk(API_PATHS.auth.login, "post", {
            ...values,
            deviceId: getMachineId(),
          })
        );
        let jwtDecoded: JwtDecoded = jwt_decode(json.accessToken);
        sessionStorage.setItem(TOKEN, json.accessToken);
        const userInfo = {
          username: jwtDecoded.user_name,
          id: jwtDecoded.user_id,
          role: jwtDecoded.role,
        };
        dispatch(saveUserInfo(userInfo));
        dispatch(authenticationIn());
      } catch (e: any) {
        if (e) {
          enqueueSnackbar(e?.errors[0]?.message, {
            variant: "error",
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [API_PATHS.auth.login, dispatch, enqueueSnackbar, fetchThunk]
  );

  return (
    <Box
      display="flex"
      height="100vh"
      style={{
        backgroundImage: `url("${background_png}")`,
        backgroundSize: "cover",
      }}
      justifyContent="center"
      alignItems={"center"}
    >
      <LoginBox onSubmit={onSubmit} />
    </Box>
  );
};
export default LoginPage;
